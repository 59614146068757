<template>
    <layout>
        <div class="w-100">
            <div class="row justify-content-center">
                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                    <div class="mx-auto login-w-limit">
                        <div class="logo-wrapper text-center mb-2">
                            <img src="@/assets/img/bau-logo-for-light.svg" height="35" />
                        </div>
                        <h4 class="text-center mb-5" style="min-height: 50px;">
                            {{ $t('conservatory') }} {{ $t('application_system') }}<br>{{$t('password_reminder')}}
                        </h4>
                        <div class="fadeIn">
                            <ValidationObserver ref="form">
                                <div class="mx-auto">
                                    <ValidationProvider name="username" rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('national_id')">
                                            <b-form-input type="text" v-model="forgotForm.national_id"
                                                :state="errors[0] ? false : null" />
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="mobile_tel" rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('gsm_no')">
                                            <b-form-input v-model="forgotForm.mobile_tel"
                                                          v-mask="'### ### ## ##'"
                                                          :state="errors[0] ? false : null" />
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>

                                        </b-form-group>
                                    </ValidationProvider>
                                    <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="invisible"
                                        sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG" />
                                    <div class="d-flex justify-content-center my-4">
                                        <b-button variant="primary" block @click="forgot()">
                                            {{ $t('send_sms').toUpper() }}
                                        </b-button>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
// Layout
import Layout from '@/modules/conservatoryApplication/layout/Layout'
// Service
import ConservatoryService from "@/services/ConservatoryService"
// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
    name: "ConservatoryForgotPassword",
    components: {
        Layout,
        ValidationObserver,
        ValidationProvider,
        VueRecaptcha
    },
    metaInfo() {
        return {
            title: this.$t("conservatory")+' '+this.$t('application_system'),
        };
    },
    data() {
        return {
            forgotForm: {
                national_id: null,
                mobile_tel: null
            }
        }
    },
    mounted() {
        this.$store.dispatch('conservatoryApplication/initData');
        this.$store.dispatch('conservatoryApplication/isLoginGoIndex');
    },
    methods: {
        async forgot() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.$refs.recaptcha.execute()
            }
        },
        onCaptchaVerified(recaptchaToken) {
            this.forgotForm.g_recaptcha_token = recaptchaToken;
            this.forgotForm.mobile_tel = this.forgotForm.mobile_tel.replace(/[^\d]/g, '')
            ConservatoryService.passwordReminder(this.forgotForm)
                .then(response => {
                    if (response.data.success) {
                        this.$toast.success(this.$t('password_send_success'))
                        this.$router.push('/conservatory/application/login')
                    }
                })
                .catch((e) => {
                    this.showErrors(e);
                }).finally(() => {
                    this.$refs.recaptcha.reset()
                })
        }
    }
}
</script>
